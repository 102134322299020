<template>
  <div class="tags_all">
    <PullRefresh
      :loading="loading"
      :refreshing="refreshing"
      :finished="finished"
      @onLoad="onLoad"
      @onRefresh="onRefresh"
      :isHigehtMax="true"
      :isNoData="isNoData"
      :error="error"
    >
      <Post class="post" :postInfo="postInfo" :index="index" v-for="(postInfo, index) in list" :key="postInfo.id" />
    </PullRefresh>
  </div>
</template>
<script>
import Post from '@/components/Post';
import { queryTagList } from '@/api/community';
import PullRefresh from '@/components/PullRefresh';
import { getSessionItem } from '@/utils/longStorage';
export default {
  components: { PullRefresh, Post },
  name: 'tagsGraphics',
  data() {
    return {
      params: {
        pageNumber: 1,
        pageSize: 10,
      },
      list: [],
      loading: true,
      refreshing: false,
      finished: false,
      isNoData: false,
      error: false,
      tagID: '',
    };
  },
  computed: {
    isSuccess() {
      return this.$store.state.user.updateTagGraphics.isSuccess;
    },
  },
  watch: {
    isSuccess: function (val) {
      if (!val) return;
      this.$store.commit('user/UPDATE_Graphics', { isSuccess: false, id: '' });
      let index = this.$store.state.user.commentData.index;
      let id = this.$store.state.user.updateTagGraphics.id;
      //先通过评论索引去修改
      if (index >= 0) {
        this.list[index].commentCount++;
        return;
      }
      //如果评论索引没找到，通过id去修改
      if (id) {
        let fIndex = this.list.findIndex((el) => el.id === id);
        this.list[index].commentCount++;
        return;
      }
    },
  },
  created() {
    this.tagID = this.$route.query.id;
    if (getSessionItem('tagId')) {
      this.tagID = getSessionItem('tagId');
    }
    this.getList();
  },
  methods: {
    async getList(type) {
      let req = {
        tagID: this.tagID,
        pageNumber: String(this.params.pageNumber),
        pageSize: String(this.params.pageSize),
        newsType: 'COVER',
      };
      try {
        let res = await this.$Api(queryTagList, req);
        this.loading = false;
        this.refreshing = false;
        if (res.code === 200) {
          let list = res.data.list || [];
          if (type === 'refresh') {
            this.list = list;
          } else {
            this.list = this.list.concat(list);
          }
          if (this.params.pageNumber == 1 && this.list.length == 0) {
            this.isNoData = true;
          }
          if (!res.data.hasNext) {
            this.finished = true;
          }
        } else {
          this.error = true;
        }
      } catch (e) {
        this.loading = false;
        this.error = true;
      }
    },
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.params.pageNumber++;
      }
      this.getList();
    },
    onRefresh() {
      this.params.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.loading = true;
      this.getList('refresh');
    },
  },
};
</script>
<style scoped lang="scss">
.tags_all {
  .post {
    padding: 11px 16px;
    margin-top: 16px;
  }
}
</style>
